import React from "react"
import { Box, Text, Flex } from "theme-ui"
import NavItem from "../navigation/menu/nav_item"

const NavGroup = props => {
  return (
    <Flex
      sx={{
        flexBasis: "50%",
        flexDirection: "column",
        mr: [null, null, 4, null, 5],
        mb: "4",
      }}
    >
      {props.blok.component === "nav_groups" && (
        // <Accordion
        //   text={props.blok.title}
        //   iconSize="32px"
        //   mobileExpand="true"
        //   sx={{
        //     borderBottom: "0px !important",
        //     borderTop: "0px !important",
        //     "& > button": {
        //       textTransform: "uppercase",
        //       py: [3, null, 0],
        //       display: [null, null, "block"],
        //     },
        //   }}
        // >
        <>
          <Text sx={{ textTransform: "uppercase", fontWeight: "bold" }}>
            {props.blok.title}
          </Text>
          <Box as="ul" sx={{ paddingInlineStart: 0 }}>
            {props.blok.nav_items &&
              props.blok.nav_items.map((blok, index) =>
                React.createElement(NavItem, {
                  key: blok._uid,
                  variant: "accordionNavItemFooter",
                  blok: blok,
                  index: index,
                })
              )}
          </Box>
        </>
        // </Accordion>
      )}
    </Flex>
  )
}

export default NavGroup
