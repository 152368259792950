import React from "react"
import SpecsListLoop from "./specs_list_loop"
import FeatureListLoop from "./feature_list_loop"
import { usePreOwnedSpecLabels } from "../../utils/pre_owned_specs"
import { useModelSpecLabels } from "../../utils/model_specs"
import SpecsListGroup from "./specs_list_group"

const SpecsListGroupLoop = props => {
  const preOwnedSpecLabels = usePreOwnedSpecLabels()
  const modelSpecLabels = useModelSpecLabels()

  const specLabels =
    props.specs_type === "pre_owned" ? preOwnedSpecLabels : modelSpecLabels

  return props.blok.spec_groups.map((specgroup, index) => {
    let specComponent = specgroup.component
    specComponent = specComponent.replace(/_/g, "-")
    const filteredSpecLabels = specLabels.filter(
      key => key.node.data_source === specComponent
    )

    return (
      <SpecsListGroup
        key={index}
        title={specgroup.component}
        type={props.specs_type}
      >
        {specgroup.component === "specs_pre_owned_features" ? (
          <FeatureListLoop blok={specgroup} />
        ) : (
          <SpecsListLoop
            blok={specgroup}
            label_array={filteredSpecLabels}
            currentLang={props.currentLang}
          />
        )}
      </SpecsListGroup>
    )
  })
}

export default SpecsListGroupLoop
