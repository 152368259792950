/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes, { object } from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

function Seo({ description, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  if (!meta) {
    return null
  } else {
    const story = Object.assign({}, meta)

    story.content = JSON.parse(story.content)
    const metaData = story.content.seo
    const translated_slugs = story.translated_slugs

    let ogImage =
      metaData.og_image ||
      (story.content.card_image && story.content.card_image.filename) ||
      story.content.main_image.filename ||
      null

    if (metaData.og_image === "") {
      metaData.og_image = ogImage && ogImage + "/m/1600x0/filters:quality(55)"
    }

    const metaDescription =
      metaData.description ||
      story.content.card_short_description ||
      site.siteMetadata.description
    const metaTitle =
      metaData.title || story.content.title || site.siteMetadata.title
    const lang = meta.lang

    const xdefault = translated_slugs.find(obj => {
      return obj.lang === "en"
    })

    const canonical = translated_slugs.find(obj => {
      return obj.lang === lang
    })

    // const urls =
    //   meta.alternateUrls &&
    //   meta.alternateUrls.map((url, index) => ({
    //     rel: url.lang === meta.lang ? "canonical" : "alternate",
    //     hreflang: url.lang,
    //     href:
    //       site.siteMetadata.siteUrl +
    //       "/" +
    //       url.lang +
    //       (url.path !== "home" ? "/" + url.path : ""),
    //   }))

    return (
      <>
        <title>{metaTitle + " | Donkervoort Cars"}</title>
        <meta name="description" content={metaDescription} />
        {metaData.og_image && metaData.og_image !== "" && (
          <meta property="og:image" content={metaData.og_image} />
        )}
        {translated_slugs &&
          translated_slugs.map((alternate, i) => (
            <link
              key={i}
              rel="alternate"
              hrefLang={alternate.lang}
              href={
                site.siteMetadata.siteUrl +
                "/" +
                alternate.lang +
                "/" +
                (alternate.path === "home"
                  ? ""
                  : alternate.path.replace(/\/?$/, "/"))
              }
            />
          ))}

        <link
          rel="alternate"
          hrefLang="x-default"
          href={
            site.siteMetadata.siteUrl +
            "/en/" +
            (xdefault.path === "home" ? "" : xdefault.path.replace(/\/?$/, "/"))
          }
        />
        <link
          rel="canonical"
          href={
            site.siteMetadata.siteUrl +
            "/" +
            lang +
            "/" +
            (canonical.path === "home"
              ? ""
              : canonical.path.replace(/\/?$/, "/"))
          }
        />
      </>
    )
  }
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  meta: PropTypes.object.isRequired,
}

export default Seo
