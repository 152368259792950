import React from "react"
import SbEditable from "storyblok-react"
import { Box } from "theme-ui"
import SpecsListGroupLoop from "./specs_list_group_loop"

const SpecsList = props => {
  return (
    <SbEditable content={props.blok}>
      <Box px={0} py={[1, null, 2]} mx={[0]}>
        <Box
          sx={{
            "& div > button": {
              color: "#000",
              "& span": {
                fontSize: "3",
                fontWeight: "bold",
                textTransform: "uppercase",
              },
              "& svg > path": { fill: "#000" },
            },
          }}
        >
          <SpecsListGroupLoop {...props} />
        </Box>
      </Box>
    </SbEditable>
  )
}
export default SpecsList
