import React from "react"
import { Box, Heading } from "theme-ui"
import { Accordion } from "../accordion"
import { useTranslations } from "../../utils/label_translations_hook"

const SpecsListGroup = props => {
  let title = useTranslations(props.title)
  if (props.type === "pre_owned") {
    return (
      <Accordion text={title}>
        <Box
          as="ul"
          sx={{
            listStyleType: "none",
            marginBlockStart: "0",
            paddingInlineStart: "0",
            columns: ["1", "1", "2"],
            columnGap: ["0", "0", 4],
          }}
        >
          {props.children}
        </Box>
      </Accordion>
    )
  } else {
    return (
      <Box mb="5">
        <Heading variant="underlined">{title}</Heading>
        <Box
          as="ul"
          sx={{
            listStyleType: "none",
            marginBlockStart: "0",
            paddingInlineStart: "0",
            columns: ["1", "1", "2"],
            columnGap: ["0", "0", 4],
          }}
        >
          {props.children}
        </Box>
      </Box>
    )
  }
}

export default SpecsListGroup
