import React from "react"
import { Flex, Box, Heading, Grid } from "theme-ui"
import { Card } from "./card"
import Section from "./section"

const RelatedContentCards = props => {
  if (!props.loop) {
    return null
  }

  const contentItems =
    props.loop && props.loop.content_items
      ? props.loop.content_items
      : props.loop

  const loop = contentItems.map((entry, i) => {
    if (!entry.content) return null

    const content = entry.content
    content.full_slug = entry.full_slug
    var link = content.full_slug
      ? { linktype: "story", story: { full_slug: content.full_slug } }
      : ""

    return (
      <Box key={i}>
        <Card
          image={content.main_image}
          imageHeading={content.title}
          //preHeading={content.availability}
          //textHeading={locationName}
          //subHeading={useDate(props.date_start, true) || ""}
          textCta={content.card_button_text || "More info"}
          link={link}
          sx={{ height: "100%" }}
        >
          {content.card_short_description}
        </Card>
      </Box>
    )
  })

  return (
    <Section
      sx={{
        backgroundColor: "cardSectionBg",
        pt: 5,
        pb: 3,
        px: [null, null, null, null, null, null, "96px"],
        overflow: "visible",
        "& > div": {
          overflow: "visible",
        },
      }}
      {...props}
    >
      <Flex
        sx={{
          mb: [3, 4],
        }}
      >
        <Heading
          as="h4"
          fontSize={[5, 5, 5, 5, 5]}
          sx={{
            maxWidth: "15ch",
            fontFamily: "body",
            fontWeight: "600",
            fontSize: [4, 5, 5, 5],
            textTransform: "uppercase",
            mb: [2, 0],
          }}
        >
          {props.title}
        </Heading>
      </Flex>
      <Grid
        columns={["1fr", null, "1fr 1fr", null, "1fr 1fr 1fr"]}
        gap={[5]}
        pb={"5"}
        sx={{ overflow: "visible" }}
      >
        {loop}
      </Grid>
    </Section>
  )
}

export default RelatedContentCards
