import { useStaticQuery, graphql } from "gatsby"

export const usePreOwnedSpecLabels = () => {
  const { allStoryblokDatasourceEntry } = useStaticQuery(
    graphql`
      query {
        allStoryblokDatasourceEntry(
          filter: {
            data_source: { regex: "/^specs-pre-owned/" }
            data_source_dimension: { eq: "nl" }
          }
        ) {
          edges {
            node {
              id
              name
              value
              data_source
              data_source_dimension
              dimension_value
            }
          }
        }
      }
    `
  )

  const entries = allStoryblokDatasourceEntry.edges

  return entries
}
