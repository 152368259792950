import React from "react"
import { Box, Text } from "theme-ui"

const FeatureListLoop = props => {
  if (!props.blok.features) {
    return null
  } else {
    return props.blok.features.map(item => (
      <Box
        as="li"
        key={item._uid}
        pt="2"
        pb="40px"
        sx={{ breakInside: "avoid-column" }}
      >
        <Text>{item.feature}</Text>
      </Box>
    ))
  }
}

export default FeatureListLoop
