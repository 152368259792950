import React from "react"
import { Heading, Grid } from "theme-ui"
import Section from "./section"

const IntroDetails = props => (
  <Section
    sx={{
      background: "#000",
      color: "#fff",
      py: [5, null, 6],
      mb: ["5", null, "6"],
    }}
    {...props}
  >
    <Heading as="h2" mb="4">
      {props.title}
    </Heading>

    <Grid gap={[4, null, 5]} columns={[2, null, 3]}>
      {props.children}
    </Grid>
  </Section>
)

export default IntroDetails
