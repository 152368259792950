import React from "react"
import { Box } from "theme-ui"
import LabelValue from "../label_value"

const SpecsListLoop = props => {
  return props.label_array.map((key, value) => {
    let lableText =
      props.currentLang === "nl" ? key.node.dimension_value : key.node.value

    return (
      props.blok[key.node.name] && (
        <Box
          as="li"
          key={key.node.name + "_" + value}
          pt="2"
          pb="40px"
          sx={{ breakInside: "avoid-column" }}
        >
          <LabelValue
            labelText={lableText}
            value={props.blok[key.node.name]}
            variant="labelValueSmall"
          />
        </Box>
      )
    )
  })
}

export default SpecsListLoop
