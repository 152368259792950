import React from "react"
import { Flex, Box, Heading } from "theme-ui"
import Section from "../section"
import Logo from "../logo"
import FooterNavGroup from "./footer_nav_group"
import NavItem from "../navigation/menu/nav_item"
import Cta from "../cta"
//import FooterLanguageSwitcher from "./footer_language_switcher"

const Footer = ({ blok, langs }) => (
  <footer>
    <Section variant="sectionNoMargin">
      <Flex
        pt={[5, null, null, 5]}
        pb={4}
        sx={{
          justifyContent: "space-between",
          bg: "footerBg",
          flexDirection: ["column", "column", "row"],
          flexWrap: "wrap",
        }}
      >
        <Flex
          sx={{
            width: ["100%", "100%", "50%", "40%"],
            order: ["3", "3", "2", "1"],
            flexDirection: "column",
            pr: [null, null, 5, 0],
          }}
        >
          <Heading as="div" sx={{ fontSize: [6, null, 7] }}>
            {blok.cta_title}
          </Heading>
          {blok.cta &&
            blok.cta.map(blok => (
              <Cta key={blok._uid} blok={blok} button_type="arrowLink" />
            ))}
        </Flex>
        <Flex
          sx={{
            width: ["100%", "100%", "100%", "20%"],
            order: ["1", "1", "1", "2"],
            justifyContent: "center",
            mb: [5, null, null, 0],
            lineHeight: "0",
          }}
        >
          <Logo
            sx={{ width: ["96px", "140px"] }}
            language={langs.currentLang}
          />
        </Flex>
        <Box
          sx={{
            width: ["100%", "100%", "50%", "40%"],
            order: ["2", "2", "3"],
            pl: [null, null, 5, 0],
            "& > div": {
              "& > ul": {
                listStyle: "none",
                paddingInlineStart: "0",
              },
            },
          }}
        >
          <Flex
            sx={{
              mb: [5, null, 4],
              ml: [null, null, null, 5],
              //flexDirection: ["row", null, "row"],
              flexWrap: ["wrap", "nowrap"],
              justifyContent: [null, null, "flex-end"],
            }}
          >
            {blok.footer_menu &&
              blok.footer_menu.map((blok, index) =>
                React.createElement(FooterNavGroup, {
                  key: blok._uid,
                  blok: blok,
                  index: index,
                })
              )}
          </Flex>
        </Box>
      </Flex>
    </Section>
    <Section sx={{ bg: "subfooterBg", mb: [0, 0, 0] }}>
      <Flex
        sx={{
          flexDirection: ["column", null, null, "row"],
          alignItems: "center",
          justifyContent: [null, null, "space-between"],
          py: 3,
        }}
      >
        <Flex sx={{ fontSize: "0", order: [2, 2, 2, 3] }}>
          © Copyright {new Date().getFullYear()} Donkervoort Automobielen.
        </Flex>
        {/* <FooterLanguageSwitcher
          langs={langs}
          sx={{
            textAlign: "center",
            mb: [2, null, null, 0],
            order: [1, 1, 1, 2],
          }}
        /> */}
        <Flex
          as="ul"
          sx={{
            order: [3, 3, 3, 1],
            flexWrap: "wrap",
            paddingInlineStart: "0",
            "& > li": {
              "&::after": {
                content: "''",
                height: "8px",
                width: "1px",
                display: "block",
                backgroundColor: "text",
                alignSelf: "center",
                mx: 2,
              },
              "&:last-of-type": {
                "::after": {
                  display: "none",
                },
              },
            },
          }}
        >
          {blok.sub_footer_menu &&
            blok.sub_footer_menu.map((blok, index) =>
              React.createElement(NavItem, {
                key: blok._uid,
                blok: blok,
                index: index,
                variant: "subFooterNavItem",
              })
            )}
        </Flex>
      </Flex>
    </Section>
  </footer>
)

export default Footer
