export function menuColor(content) {
  const templates = [
    "homepage",
    "model",
    "pre_owned",
    "event",
    "event_location",
    "timeline",
    "news_overview",
    "jobs_overview",
    "event_overview",
    "pre_owned_overview",
    "models_overview",
    "job",
  ]
  if (
    templates.includes(content.component) &&
    content.main_image !== "undefined"
  ) {
    return "white"
  } else {
    return "black"
  }
}
